<style scoped lang="scss">
.tab button {
  border: none;
  background-color: #fff;
  color: #004bfa;
  cursor: pointer;
}

.tab button.active {
  background-color: #004bfa;
  color: #fff;
}
.tab div.show {
  display: block;
}
.Home {
  font-size: 0.359rem;
  .bg {
    background: url(https://file.jiaoxin-tech.cn/feiyu/app/layerOrder1.png) no-repeat;
    background-size: 100% 110%;
    width: 100%;
    height: 12.2564rem;
    position: relative;
    .tab {
      display: inline-block;
      overflow: hidden;
      margin: 3.9949rem 0.5128rem 0;
      border-radius: 0.3846rem;
      button {
        height: 0.7692rem;
        font-size: 0.359rem;
      }
      .left-half {
        width: 1.4872rem;
      }
      .right-half {
        width: 1.7179rem;
      }
    }
  }
}

.search {
  input {
    width: 7.3rem;
    height: 1.026rem;
    padding: 0 0.2564rem;
    border-top-left-radius: 0.1026rem;
    border-bottom-left-radius: 0.1026rem;
  }
  button {
    background-color: #004bfa;
    color: #fff;
    width: 1.7692rem;
    height: 1.0256rem;
    font-size: 0.3846rem;
    border-top-right-radius: 0.1026rem;
    border-bottom-right-radius: 0.1026rem;
    line-height: 1.1256rem;
  }
}
.content {
  background: #fff;
  width: 100%;
  min-height: 10.5385rem;
  border-top-left-radius: 0.7692rem; /* 去掉左上角的圆角 */
  border-top-right-radius: 0.7692rem; /* 去掉右上角的圆角 */
}
.Cascader .CascaTit {
  padding: 0.1923rem 0.3846rem;
  font-size: 0.359rem;
  background: #fff;
  color: #000;
  align-items: center;
  font-weight: 500;
  img {
    width: 0.641rem;
    filter: grayscale(65%) brightness(157%) contrast(139%);
  }
}
.CascaderBox {
  position: relative;
  &::after {
    content: "";
    display: inline-block;
    width: 0.02565rem;
    height: 0.6128rem;
    position: absolute;
    top: 0.2565rem;
    background: #e1e1e1;
  }
}
.CascaderBox:nth-last-child(-n + 2) {
  margin-left: -0.0256rem;
}
.no-after::after {
  content: none;
  display: none;
}
.cl {
  padding: 0.5128rem;
}

.rotate {
  transform: rotate(180deg);
  transition: transform 0.3s ease; /* 添加过渡效果 */
}

.rotateBack {
  transform: rotate(0deg);
  transition: transform 0.3s ease; /* 添加过渡效果 */
}
.dropdown {
  background: #fff;
  text-align: center;
  margin-top: 0.0513rem;
  font-weight: 500;
  position: absolute;
  top: 1.1051rem;
  left: 0px;
  width: 100%;
  z-index: 99;
  .option {
    line-height: 1.0513rem;
    font-size: 0.359rem;
    color: #000;
  }
  .option:hover {
    background: #e0f3ff;
  }
}
.van-loading--vertical {
  position: fixed;
  top: 40%;
  left: 44%;
  z-index: 99;
}
.rules {
  position: absolute;
  top: 1.7179rem;
  right: 0;
  width: 0.5897rem;
  img {
    width: 100%;
  }
}
</style>
<template>
  <!-- header -->
  <div class="Home container">
    <!-- 背景 -->
    <div class="bg">
      <!-- 开始tab -->
      <div class="tab">
        <button class="left-half" :class="{ active: activeTab === 'Group' }" @click="AddactiveTab('Group', 1)">团体</button>
        <button class="right-half" :class="{ active: activeTab === 'SingleMatch' }" @click="AddactiveTab('SingleMatch', 2), fetchLevel1Options()">单项赛</button>
      </div>
      <!-- 结束tab -->
      <!-- 开始三级联动 -->
      <div v-show="activeTab === 'SingleMatch'" class="flex cl Cascader">
        <!-- 组别下拉菜单 -->
        <div class="CascaderBox no-after">
          <div class="flex CascaTit" @click="toggleDropdown(1)">
            <div>{{ level1Title == "" ? "组别" : level1Title }}</div>
            <img :class="{ rotate: activeDropdown === 1, rotateBack: activeDropdown !== 1 }" src="../assets/image/arrow.png" alt="" />
          </div>
          <div v-show="activeDropdown === 1" class="dropdown">
            <div class="option" v-for="option in level1Options" :key="option.id" @click="handleSelect1(option)">
              <span v-if="option.id !== 1">{{ option.group_name }}</span>
            </div>
          </div>
        </div>
        <!-- 项目下拉菜单 -->
        <div class="CascaderBox">
          <div class="flex CascaTit" @click="toggleDropdown(2)">
            <div>{{ level2Title == "" ? "项目" : level2Title }}</div>
            <img :class="{ rotate: activeDropdown === 2 }" src="../assets/image/arrow.png" alt="" />
          </div>
          <div v-show="activeDropdown === 2" class="dropdown">
            <div class="option" v-for="option in level2Options" :key="option.id" @click="handleSelect2(option)">
              {{ option.type_name }}
            </div>
          </div>
        </div>
        <!-- 分组下拉菜单 -->
        <div class="CascaderBox" v-show="activeTab === 'SingleMatch'">
          <div class="flex CascaTit" @click="toggleDropdown(3)">
            <div>{{ level3Title == "" ? "分组" : level3Title }}</div>
            <img :class="{ rotate: activeDropdown === 3 }" src="../assets/image/arrow.png" alt="" />
          </div>
          <div v-show="activeDropdown === 3" class="dropdown">
            <div class="option" v-for="option in level3Options" :key="option.id" @click="handleSelect3(option)">
              {{ option.types_group_name }}
            </div>
          </div>
        </div>
      </div>
      <!-- 结束三级联动 -->
      <!-- 开始搜索 -->
      <div class="search cl flex">
        <input type="text" v-model="queryValue" placeholder="输入姓名或者证件号码后四位查询" />
        <button @click="Addquery(queryValue)">查询</button>
      </div>
      <!-- 结束搜索 -->
      <!-- 开始数据渲染内容 -->
      <div class="Group">
        <div class="content cl">
          <DataGroup :tableData="tableData" :columns="columns" />
        </div>
      </div>
      <!-- 结束数据渲染内容 -->
      <!-- 开始积分规则说明 -->
      <div class="rules" @click="router.push({ path: 'rules' })">
        <img src="https://file.jiaoxin-tech.cn/feiyu/chaobabei/ruls.png" alt="" />
      </div>
      <!-- 结束积分规则说明 -->
    </div>

    <van-overlay :show="overlayShow">
      <van-loading color="#0094ff" size="50px" vertical>加载中...</van-loading>
    </van-overlay>
  </div>
  <!-- header -->
</template>
<script setup>
import router from "../router/index";
import DataGroup from "@/components/DataGroup.vue";
import { ref } from "vue";
import { Packet, queryName, Group, Project, Grouping } from "@/api/index";
const overlayShow = ref(false);
const activeTab = ref("Group");
const queryValue = ref("");
const columns = [
  {
    title: "排名",
    key: "ranking",
    align: "center",
    className: "classIndex",
    width: "1.5558rem",
    // rowSpan: (row, index, data) => {
    //   return row.rowSpan;
    // },
  },
  {
    title: "姓名",
    key: "name",
    align: "center",
    width: "2.5841rem",
    className: "className",
  },
  {
    title: "证件号码",
    key: "id_number",
    align: "center",
    className: "classID",
  },
  {
    title: "总积分",
    key: "user_points",
    align: "center",
    className: "classScore",
    width: "2.07rem",
    // rowSpan: (row, index, data) => {
    //   return row.rowSpan;
    // },
  },
];
const tableData = ref([]);
const AddactiveTab = (name, index) => {
  tableData.value = [];
  activeTab.value = name;
  overlayShow.value = true;
  level1Options.value = [];
  level2Options.value = [];
  level3Options.value = [];
  level1Title.value = "";
  level2Title.value = "";
  level3Title.value = "";
  Packet({ eventId: 1, userTypeId: index, typeId: null })
    .then(({ data }) => {
      if (data.error_code !== 0) {
        // 显示错误提示
        return;
      }
      if (data.data.value === 0) {
        tableData.value = [];
        return;
      }
      maskIdNumbers(data.data);
      tableData.value = data.data;
      overlayShow.value = false;
    })
    .catch((error) => {
      overlayShow.value = false;
      console.error("获取组别数据失败:", error);
    })
    .finally(() => {
      overlayShow.value = false;
    });
};

const maskIdNumbers = (obj) => {
  if (Array.isArray(obj)) {
    obj.forEach(maskIdNumbers);
  } else if (obj && typeof obj === "object") {
    Object.entries(obj).forEach(([key, value]) => {
      if (key === "id_number") {
        if (typeof value === "string" && value.trim()) {
          const visibleStart = value.slice(0, 3);
          const visibleEnd = value.slice(-4);
          const maskedMiddle = "****"; // 中间始终用4个*替换
          obj[key] = visibleStart + maskedMiddle + visibleEnd;
        } else {
          obj[key] = "-";
        }
      } else if (typeof value === "object") {
        maskIdNumbers(value);
      }
    });
  }
};
// 组团逻辑
// const list = tableData.value.flatMap((item, i) => {
//   if (Array.isArray(item.name)) {
//     return item.name.map((m, index) => ({
//       name: m,
//       id_number: item.id_number[index].slice(-4).padStart(item.id_number[index].length, "*"),
//       index: i + 1,
//       user_points: item.user_points,
//       rowSpan: index == 0 ? item.name.length : 0,
//     }));
//   } else {
//     return {
//       name: item.name,
//       id_number: item.id_number.slice(-4).padStart(item.id_number.length, "*"),
//       index: i + 1,
//       user_points: item.user_points,
//       rowSpan: 1,
//     };
//   }
// });

// 下拉菜单的选项数据
const level1Options = ref([]);
const level2Options = ref([]);
const level3Options = ref([]);
const level1Title = ref("");
const level2Title = ref("");
const level3Title = ref("");

let leveL1 = ref("");
let leveL2 = ref("");
const activeDropdown = ref(null);
// 模拟获取数据的接口请求
const fetchLevel1Options = () => {
  Group({ eventId: 1 })
    .then(({ data }) => {
      console.log(data);
      if (data.error_code !== 0) {
        overlayShow.value = false;
        // 显示错误提示
        return;
      }
      if (data.data.value === 0) {
        level1Options.value = [];
        overlayShow.value = false;
        return;
      }
      level1Options.value = data.data;
    })
    .catch((error) => {});
};
const fetchLevel2Options = (parentId) => {
  overlayShow.value = true;
  Project({ eventGroupId: parentId })
    .then(({ data }) => {
      if (data.error_code !== 0) {
        overlayShow.value = false;
        // 显示错误提示
        return;
      }
      if (data.data.value === 0) {
        level1Options.value = [];
        overlayShow.value = false;
        return;
      }
      level2Options.value = data.data;
      overlayShow.value = false;
    })
    .catch((error) => {
      overlayShow.value = false;
      console.error("获取组别数据失败:", error);
    });
};
const fetchLevel3Options = (parentId) => {
  overlayShow.value = true;
  Grouping({ eventGroupTypeId: parentId })
    .then(({ data }) => {
      if (data.error_code !== 0) {
        overlayShow.value = false;
        // 显示错误提示
        return;
      }
      if (data.data.value === 0) {
        level1Options.value = [];
        overlayShow.value = false;
        return;
      }
      level3Options.value = data.data;
      overlayShow.value = false;
    })
    .catch((error) => {
      overlayShow.value = false;
      console.error("获取组别数据失败:", error);
    });
};
// 切换下拉菜单显示/隐藏
const toggleDropdown = (dropdown) => {
  activeDropdown.value = dropdown === activeDropdown.value ? null : dropdown;
};

// 处理第一个下拉菜单的选择
const handleSelect1 = (option) => {
  level1Title.value = option.group_name;
  console.log(level1Title);
  console.log("第一个下拉菜单选择了：" + JSON.stringify(option));
  level2Options.value = [];
  level3Options.value = [];

  fetchLevel2Options(option.id);
  activeDropdown.value = null;
  toggleDropdown(2);
  leveL1 = option.id;
};

// 处理第二个下拉菜单的选择
const handleSelect2 = (option) => {
  level2Title.value = option.type_name;
  console.log("第二个下拉菜单选择了：" + option.type_name);
  level3Options.value = [];
  fetchLevel3Options(option.id);
  leveL2 = option.id;
  activeDropdown.value = null;
  toggleDropdown(3);
};
// 处理第三个下拉菜单的选择
const handleSelect3 = (option) => {
  level3Title.value = option.types_group_name;
  overlayShow.value = true;
  activeDropdown.value = null;
  Packet({ eventId: 1, userTypeId: 2, groupId: leveL1, typeId: leveL2, typeGroupId: option.id })
    .then(({ data }) => {
      if (data.error_code !== 0) {
        overlayShow.value = false;
        // 显示错误提示
        return;
      }
      if (data.data.value === 0) {
        tableData.value = [];
        overlayShow.value = false;
        return;
      }
      maskIdNumbers(data.data);
      tableData.value = data.data;
      overlayShow.value = false;
    })
    .catch((error) => {
      overlayShow.value = false;
      console.error("获取组别数据失败:", error);
    });
};
// 团体搜索
const Addquery = () => {
  if (typeof value === "") overlayShow.value = true;
  queryName({ eventId: 1, queryName: queryValue.value })
    .then(({ data }) => {
      if (data.error_code !== 0) {
        overlayShow.value = false;
        // 显示错误提示
        return;
      }
      if (data.data.value === 0) {
        tableData.value = [];
        overlayShow.value = false;
        return;
      }
      maskIdNumbers(data.data);
      tableData.value = data.data;
      overlayShow.value = false;
    })
    .catch((error) => {
      overlayShow.value = false;
      console.error("获取组别数据失败:", error);
    });
};

AddactiveTab("Group", 1);
</script>
