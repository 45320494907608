import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/Style/index.css'
import { Button, Overlay,Loading,Toast,NavBar   } from 'vant';
import 'vant/lib/index.css'; // 引入 Vant 的样式;
import 'vant/es/toast/style';
createApp(App).use(store).use(router).use(Button).use(Overlay).use(Loading).use(Loading).use(Toast).use(NavBar).mount('#app')

if (process.env.NODE_ENV === 'production') {
    console.log = function () {};
    console.warn = function () {};
    console.error = function () {};
  }
