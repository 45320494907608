<template>
  <router-view />
 
</template>
<script>

function setRemUnit() {
  const baseSize = 39; // 设计图宽度的10%
  const designWidth = 390; // 设计图宽度
  const clientWidth = document.documentElement.clientWidth;
  const scale = clientWidth / designWidth; // 计算缩放比例

  if (clientWidth >= 1024) {
    document.documentElement.style.fontSize = "62px"; // 固定字体大小
  } else {
    document.documentElement.style.fontSize = baseSize * scale + "px";
  }
}

// 初始化
setRemUnit();
// 禁止双击缩放
let lastTouchEnd = 0;
document.addEventListener('touchend', function (event) {
    let now = new Date().getTime();
    if (now - lastTouchEnd <= 300) {
        event.preventDefault();
        if(event.target.tagName.toLowerCase() === 'input'){
            event.target.focus(); // 如果点击的是input元素，则让其获得焦点
        }
    }
    lastTouchEnd = now;
}, false);
</script>
<style lang="scss">
/* 初始设置根元素的字体大小 */
html {
  font-size: 39px; /* 默认字体大小为设计图宽度的10% */
}
/* 使用 rem 单位设置元素尺寸 */
.example-element {
  width: 2rem; /* 78px / 39px = 2rem */
  height: 5rem; /* 195px / 39px = 5rem */
  background-color: #3498db;
}
</style>
