<template>
  <div v-for="(group, index) in tableData" :key="index">
    <div class="title" v-for="(value, key) in group" :key="key">
      <span>{{ key }}</span>
    </div>
    <n-data-table v-for="item in group" :columns="columns" :key="item.ranking" :data="item" :bordered="false" :row-class-name="rowClassName" :class="{ 'no-max-height': shouldRemoveMaxHeight(tableData) }" />
  </div>
</template>
<script setup>
import { NDataTable } from "naive-ui";
const props = defineProps({
  tableData: {
    type: Array,
    required: true,
  },
  columns: {
    type: Array,
    required: true,
  },
});
// 添加类名去除下线
const rowClassName = (row) => {
  if (row.rowSpan === 2) {
    return "too-old"; // 如果行的数据中存在 index 字段，则返回 "too-old" 类名
  } else {
    return ""; // 否则返回空字符串，表示不添加额外的类名
  }
};
// 计算属性，用于判断是否需要移除 max-height
const shouldRemoveMaxHeight = (group) => {
  // 判断组内的项目是否只有一个
  return Object.keys(group).length === 1;
};
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 0.2564rem;
  color: #c6472a;
  font-size: 0.359rem;
  border-radius: 0.1538rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  span {
    background: #fff6f4;
    padding: 0.1564rem 0.3077rem;
    border-radius: 0.1538rem;
  }
}
.n-data-table{margin-bottom:.2564rem}
:deep(.classIndex){color:#f2bd41;background-color:#fff!important;font-size:.3846rem}
:deep(.className){color:#1e428a;background-color:#fff!important;font-size:.3846rem}
:deep(.classID){color:#000;background-color:#fff!important;font-size:.3846rem}
:deep(.classScore){color:#000;background-color:#fff!important;font-size:.3846rem}
:deep(.too-oldtd:nth-child(2)){border-bottom:none}
:deep(.too-oldtd:nth-child(3)){border-bottom:none}
:deep(.n-data-table-th){color:#999!important;font-size:.3846rem;background:#fff!important}
:deep(.n-data-table-wrapper){max-height:8.6923rem;overflow-y:auto}
:deep(.no-max-height .n-data-table-wrapper) {
  max-height: none;
}
</style>
