import axios from 'axios';
import router from '../router/index';
const baseURL = process.env.VUE_APP_API_URL;
// const baseURL = 'https://ranking.feiyutiyu.cn/';
const timeout = 5000;

const instance = axios.create({
  baseURL,
  timeout,
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    return response;
  },
  error => {
    if (!error.response) {
      // 如果没有response，可能是网络问题
      return router.push({name:'Timeout'})
    }
    const { status } = error.response;
    // 根据不同的状态码处理错误
    switch (status) {
      case 401:
        console.error("未授权，请重新登录");
        // 这里可以重定向到登录页面，或做其他处理
        break;
      case 403:
        console.error("拒绝访问");
        break;
      case 404:
        console.error("请求地址错误");
        break;
      case 500:
        console.error("服务器内部错误");
        break;
      default:
        console.error(`请求错误，状态码：${status}`);
    }
    return Promise.reject(error.response);
  }
);
// 封装请求方法
export const request = async (config) => {
  try {
    const response = await instance(config);
    return response;
  } catch (error) {
    throw error;
  }
};

export default instance;