import { createRouter, createWebHashHistory } from 'vue-router'
import index from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    title:'超霸杯',
    component: index
  },
  {
    path: "/hello",
    //路由懒加载
    component: () => import("../components/DataGroup.vue"),
  },
  {
    path: "/timeout",
    name:'Timeout',
    //路由懒加载
    component: () => import("../views/Timeout.vue"),
  },
  {
    path: "/rules",
    name:'Rules',
    //路由懒加载
    component: () => import("../views/Rules.vue"),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
