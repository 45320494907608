import { request } from "../utils/request";

// 表格数据接口
export function Packet(params) {
  return request({
    url: `api/users/points/ranking`,
    method: "post",
    data: params,
  });
}
// 搜索接口
export function queryName(params) {
  return request({
    url: `api/users/pointsranking/queryname`,
    method: "post",
    data: params,
  });
}
// 组别接口
export function Group(params) {
  return request({
    url: `manager/getSportsEventGroups`,
    method: "post",
    data: params,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
}
// 项目接口
export function Project(params) {
  return request({
    url: `manager/getSportsEventGroupTypes`,
    method: "post",
    data: params,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
}
// 分组接口
export function Grouping(params) {
  return request({
    url: `manager/getSportsEventGroupTypeGroups`,
    method: "post",
    data: params,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
}
